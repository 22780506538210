<template>
  <div class="row">
    <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
      <div class="d-flex align-items-center justify-content-between">
        <h4>{{$t('label.list') }}</h4>
      </div>
      <div class="create-workform">
        <button type="button" class="btn btn-primary position-relative d-flex align-items-center justify-content-between" data-bs-toggle="modal" data-bs-target="#modalcal">
          <svg xmlns="http://www.w3.org/2000/svg" class="me-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6">
            </path>
          </svg>
          {{$t('button.add') }}
        </button>
      </div>
    </div>
    <!-- {{user}} -->
    <div class="col-sm-12" v-for="(item, index) in friendRequest" :key="index">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ item.title }}</h4>
        </template>
        <template v-slot:body>

          <ul class="request-list list-inline m-0 p-0" v-infinite-scroll="getRows" :infinite-scroll-disabled="commomData.noResult" infinite-scroll-distance="20">
            <li v-for="(list, index1) in item.list" class="d-flex align-items-center justify-content-between flex-wrap " :key="index1">
              <!-- <div class="user-img img-fluid flex-shrink-0">
                <img
                  :src="list.img"
                  alt="story-img"
                  class="rounded-circle avatar-40"
                />
              </div> -->
              <div class="flex-grow-1 ms-3">
                <h4>{{ list.favorite_category_name }}</h4>
                <!-- <p class="mb-0">{{ list.friend }}</p> -->
              </div>
              <div class="d-flex align-items-center mt-2 mt-md-0">
                <div class="confirm-click-btn">
                  {{ list.friend }} 空的
                </div>
              </div>
            </li>
            <li v-if="!item.list.length" class="text-center mb-0 pb-0">
              目前沒有任何名單
            </li>
            <!-- <li v-if="commomData.listLoader" class="d-block text-center mb-0 pb-0">
              <img src="@/assets/images/page-img/page-load-loader.gif" alt="loader" style="height: 100px" />
            </li> -->
          </ul>
        </template>
      </iq-card>
    </div>

  </div>

  <modal ref="modalcal" id="modalcal" tabindex="-1" title="新增名單" aria-labelledby="modalcalLabel" aria-hidden="true">
    <form>
      <model-header>
        <h5 class="modal-title" id="modalcalLabel">{{ $t('label.add') }}{{ $t('label.new') }}{{$t('label.list') }}</h5>
        <button :dismissable="true" type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </model-header>
      <model-body>
        <div class="row">
          <div class="col-md-12 mb-3">
            <label for="Text1" class="form-label font-weight-bold text-muted text-uppercase">{{ $t('label.list') }}{{ $t('label.name') }}</label>
            <input type="text" v-model="form.favorite_category_name" class="form-control mb-0" id="favorite_category_name" name="favorite_category_name" :placeholder="$t('label.favoriteCategoryNamePlaceholder')"
              :class="{ 'is-invalid': v$.form.favorite_category_name.$dirty && !v$.form.favorite_category_name.$pending && v$.form.favorite_category_name.$invalid }" />
            <span v-if="v$.form.favorite_category_name.$dirty && !v$.form.favorite_category_name.$pending && v$.form.favorite_category_name.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
          </div>
        </div>
      </model-body>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{$t('button.cancel')}}
        </button>
        <button @click="onSubmit()" type="button" class="btn btn-primary">
          <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
          {{$t('button.save')}}</button>
      </div>
    </form>
  </modal>
</template>
<script>
import { socialvue } from '../../../config/pluginInit'
import { addFavorite, getFavoriteRows } from '@/api/favorite'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'

export default {
  name: 'FriendRequest',
  setup() {
    const v$ = useVuelidate()
    return { v$ }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState'
    })
  },
  mounted() {
    socialvue.index()
  },
  created() {
    this.getRows()
  },
  data() {
    return {
      message: '',
      commomData: commomData(),
      primaryKey: 'favorite_category_id',
      friendRequest: [
        {
          title: this.$t('label.customList'),
          btn1: 'Confirm',
          btn2: 'Delete Request',
          list: [
            // {
            //   img: require('../../../assets/images/user/05.jpg'),
            //   name: '粉絲有買',
            //   friend: '2 人物 73 貼子'
            // },
            // {
            //   img: require('../../../assets/images/user/06.jpg'),
            //   name: 'Following',
            //   friend: '空的'
            // }
          ]
        }
      ],
      form: {
        favorite_category_name: ''
      }
    }
  },
  validations() {
    return {
      form: {
        favorite_category_name: { required }
      }
    }
  },
  methods: {
    async onSubmit() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }
      this.commomData.btnLoader = true
      addFavorite(this.form)
        .then(() => {
          this.commomData.condition.page = 1
          this.commomData.noResult = false
          this.$refs.modalcal.hide()
          this.getRows()
          this.form.favorite_category_name = ''
          this.commomData.btnLoader = false
        })
        .catch(err => {
          this.commomData.btnLoader = false
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    },
    async getRows() {
      if (this.commomData.noResult) {
        return false
      }
      this.commomData.listLoader = true
      this.commomData.condition.order_by = this.primaryKey
      await getFavoriteRows(this.commomData.condition)
        .then(res => {
          if (this.commomData.condition.page === 1) {
            this.friendRequest[0].list = res.data
            this.commomData.condition.page = 2
            this.commomData.listLoader = false
            if (res.total === 0) {
              this.commomData.noResult = true
            }
          } else if (res.total > this.friendRequest[0].list.length) {
            this.friendRequest[0].list.push(...res.data)
            this.commomData.condition.page = this.commomData.condition.page + 1
            this.commomData.listLoader = false
          } else if (res.total === this.friendRequest[0].list.length) {
            this.commomData.noResult = true
            this.commomData.listLoader = false
          }
        })
        .catch(err => {
          console.log(err)
          this.commomData.btnLoader = false
          this.$swal.mixin().fire({
            icon: 'error',
            title: 'err.response.data.message'
          })
        })
    },
    deleterequest() {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-primary btn-lg',
          cancelButton: 'btn btn-outline-primary btn-lg ms-2'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          showClass: {
            popup: 'animate__animated animate__zoomIn'
          },
          hideClass: {
            popup: 'animate__animated animate__zoomOut'
          }
        })
        .then(willDelete => {
          if (willDelete.isConfirmed) {
            swalWithBootstrapButtons.fire({
              title: 'Deleted!',
              text: 'Your Request has been deleted.',
              icon: 'success',
              showClass: {
                popup: 'animate__animated animate__zoomIn'
              },
              hideClass: {
                popup: 'animate__animated animate__zoomOut'
              }
            })
          } else {
            swalWithBootstrapButtons.fire({
              title: 'Your Request is safe!',
              showClass: {
                popup: 'animate__animated animate__zoomIn'
              },
              hideClass: {
                popup: 'animate__animated animate__zoomOut'
              }
            })
          }
        })
    }
  }
}
</script>
